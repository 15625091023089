import React from 'react'
//import { HamburgerButton, Menu } from '@accodeing/gatsby-theme-heimr'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  //const [menuOpen, setMenuOpened] = useState(false)

  const data = useStaticQuery(graphql`
    query data {
      background: file(relativePath: { eq: "header-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80, rotate: -90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <oma-grid-row fullWidth>
      <header className='header'>
        <BackgroundImage
          fluid={data.background.childImageSharp.fluid}
          className='header__background-image'
        >
          <p className='header__title'>En salig röra</p>
          {/*
          <HamburgerButton
            open={menuOpen}
            onOpen={() => setMenuOpened(true)}
            onClose={() => setMenuOpened(false)}
          />
          <Menu open={menuOpen} />
          */}
        </BackgroundImage>
      </header>
    </oma-grid-row>
  )
}
