import React from 'react'

export default () => (
  <oma-grid-row
    contentAlignedWithGrid
    fullWidth
    class='row row--with-top-margin'
  >
    <footer className='footer'>
      <div>
        Skapad av{' '}
        <a href='https://dinwebbstudio.se/'>Din Webbstudio i Gyllby</a>
      </div>
    </footer>
  </oma-grid-row>
)
